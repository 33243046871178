import * as React from 'react';
import { Typography, Link } from '@mui/material';
import {PropsWithChildren} from "react";
import {StyledChunks, StyledWords} from "../highlighter/HighLightReconciliation";
import {useHighlighting} from "../highlighter/HighLightText";

interface PaperViewerProps {
    title: string;
    link: string;
    styledChunks?: ReadonlyArray<StyledChunks>;
    styledWords?: ReadonlyArray<StyledWords>;
}

export const PaperViewerWrapper: React.FunctionComponent<PropsWithChildren<PaperViewerProps>> = (props) => {

    const title = useHighlighting(props.title, {
        chunks: props.styledChunks,
        words: props.styledWords
    });

    return (
        <>
            <Typography align={"justify"} sx={{ color:"#555",fontWeight: 600,fontSize:12}}>
                <Link
                    href={props.link}
                    underline="hover"
                    align="justify"
                    target="_blank"
                    rel="noopener noreferrer"

                >
                    <span
                        dangerouslySetInnerHTML={{__html: title}}
                    />
                </Link>
            </Typography>
            {props.children}
        </>
    );
};
