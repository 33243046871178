import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {
    getDocuments, getFilters,
    getLastClickedPoint,
    getLastHoveredPoint, getMaxArticles, getQuery,
    getSelectedDocuments,
    getSelectedSources
} from "../../store/selectors";
import {SectionInformationPaper} from "../../components/SectionInformationPaper";
import {ScicartaDocument} from "../../types/DocumentData";
import {Point} from "../../types/PlotData";
import {useStoreDispatch} from "../../store/store";
import {documentsRemoveSelectedDocumentAction} from "../../store/actions/documents/removeSelectedDocument";
import {AppQuery, StoredFilterElement} from "../../types/AppQuery";

const getDocumentForPoint = (point: Point | undefined, documents: Readonly<Record<string, ScicartaDocument>>) => {
    if (point) {
        if (point.document_id in documents) {
            return documents[point.document_id];
        }
    }

    return undefined;
}

export const PaperInformationPage: React.FunctionComponent = () => {
    const lastHoveredPoint = useSelector(getLastHoveredPoint);
    const lastClickedPoint = useSelector(getLastClickedPoint);
    const documents = useSelector(getDocuments);
    const selectedDocuments = useSelector(getSelectedDocuments);
    const sources = useSelector(getSelectedSources);
    const filters = useSelector(getFilters);
    const query = useSelector(getQuery);
    const maxArticles = useSelector(getMaxArticles);

    const dispatch = useStoreDispatch();

    const lastHoveredDocument = getDocumentForPoint(lastHoveredPoint, documents);
    const lastClickedDocument = getDocumentForPoint(lastClickedPoint, documents);

    const appQuery = useMemo<AppQuery<StoredFilterElement>>(() => ({
        sources: [...sources],
        maxArticles,
        filters: Object.values(filters),
        query
    }), [sources, filters, query]);

    return <SectionInformationPaper
        clickedDocument={lastClickedDocument}
        selectedDocuments={Object.values(selectedDocuments)}
        hoveredDocument={lastHoveredDocument}
        onDeleteDocument={(documentId: string) => dispatch(documentsRemoveSelectedDocumentAction(documentId))}
        appQuery={appQuery}
    />;
}
