import * as React from 'react';
import {PaperViewerWrapper} from "../PaperViewerWrapper";
import {PaperViewerParagraph} from "../PaperViewerParagraph";
import {Box} from '@mui/material';
import {DocumentPubmed} from "../../../types/DocumentData";
import {PaperViewerDocumentProps} from "./PaperViewerDocumentProps";
import {useStyledWords} from "./useStyledWords";
import {useStyledChunks} from "./useStyledChunks";
import {abbreviate} from "../../../utils/Number";
import {joinOrUndefined} from "../../../utils/Array";

const extractAuthors = (authors: Array<string>): [string, string] | [string] | [] => {
    if (authors.length === 0) {
        return [];
    } else if (authors.length === 1) {
        return [authors[0]];
    } else {
        return [authors[0], authors[authors.length - 1]];
    }
}

function emptyStringIfUndefined(value: unknown): string {
    if (value === undefined || value === "") {
        return "";
    } else {
        return String(' - ' + value);
    }
}

function emptyStringIfUndefinedNoDash(value: unknown): string {
    if (value === undefined || value === "") {
        return "";
    } else {
        return String(value);
    }
}

export const PaperViewerDocumentPubmed: React.FunctionComponent<PaperViewerDocumentProps<DocumentPubmed>> = (props) => {

    const searchStyles = useStyledWords(props.document.highlightKeywords, props.appQuery, true, false);
    const searchStylesTitle = useStyledWords(props.document.highlightKeywords, props.appQuery, false, true);
    const chunks = useStyledChunks(props.document.highlighting, props.appQuery.filters);

    const authors = extractAuthors(props.document.authors);
    const companyAffiliations =  emptyStringIfUndefinedNoDash(joinOrUndefined(props.document.affiliation, ', '));
    const allAuthors = emptyStringIfUndefined(joinOrUndefined(props.document.authors, ', '));
    const mesh = props.document.mesh.map((text) => text ).join(', ');
    const substances = props.document.substances.map((text) => text ).join(', ');
    const keywords = emptyStringIfUndefined(joinOrUndefined(props.document.keywords, ', '));

    return (
        <Box mr={2}>
                <PaperViewerWrapper
                    title={props.document.title}
                    link={props.document.doi_href}
                    styledWords={searchStylesTitle}
                >
                    <PaperViewerParagraph title={"First, Last Authors:"}
                                          content={`${authors.join(', ')} - ${new Date(props.document.date).toLocaleDateString('en-US')}`}
                                          styledWords={searchStyles}
                                          isInline
                    />
                    <PaperViewerParagraph title={"Journal:"}
                                          content={props.document.journal}
                                          styledWords={searchStyles}
                                          styledChunks={chunks.journal}
                                          isInline
                    />
                    <PaperViewerParagraph title={"Paper id:"}
                                          content={`PMID: ${props.document.id}`}
                                          styledWords={searchStyles}
                                          isInline
                    />
                    <PaperViewerParagraph title={"Company Affiliations:"}
                                          content={companyAffiliations}
                                          styledWords={searchStyles}
                                          isInline
                    />
                    <PaperViewerParagraph title={"Abstract"}
                                          content={props.document.abstract}
                                          styledWords={searchStyles}
                                          styledChunks={chunks.abstract}
                    />
                    <PaperViewerParagraph title={"All Authors:"}
                                          content={allAuthors}
                                          styledWords={searchStyles}
                                          isInline
                    />
                    <PaperViewerParagraph title={"Keywords:"}
                                          content={keywords}
                                          styledWords={searchStyles}
                                          isInline
                    />
                    <PaperViewerParagraph title={"Pubmed citations:"}
                      content={abbreviate(props.document.citations)}
                      styledWords={searchStyles}
                      isInline
                    />
                    <PaperViewerParagraph title={"MeSH"}
                                          content={mesh}
                                          styledWords={searchStyles}
                    />
                    <PaperViewerParagraph title={"Substances"}
                                          content={substances}
                                          styledWords={searchStyles}
                    />
                </PaperViewerWrapper>
        </Box>
    );
};
