import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import {ModalDialogTitle} from "./ModalDialogTitle";
import {Box, Link} from "@mui/material";

const ModalDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    }
}));

interface PrivacyPolicyProps {
    isOpen : boolean;
    handleClose: () => void;
}

export const PrivacyPolicy: React.FunctionComponent<PrivacyPolicyProps> = (props) => {

    return (
        <div>
            <ModalDialog
                fullWidth={true}
                maxWidth={'lg'}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}
            >
                <ModalDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                        Privacy Policy
                </ModalDialogTitle>
                <DialogContent dividers>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: '100%',
                        }}
                    >
                        <Typography gutterBottom align={"justify"} fontWeight={"lighter"} variant={"caption"}>

                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>Scicarta Inc. Policy on Privacy </Typography>
                        <Typography paragraph={true} >
                            Scicarta, Inc. (“<b>Scicarta</b>”, “<b>we</b>”, “<b>our</b>” or “<b>us</b>”) understands that privacy is important to our website users.
                            This Privacy Policy sets out how we will collect and use the personal information you provide to us while using the website located at <Link href={"https://scicarta.com"} underline={"hover"}>https://scicarta.com</Link> (the “<b>Site</b>”). This Privacy Policy applies solely to the Site. It does not apply to any other site, product or service of Scicarta, its affiliates, third party sellers, or customers.
                        </Typography>
                        <Typography paragraph={true} gutterBottom>
                        By accessing or using the Site, you are deemed to accept the terms of this Privacy Policy. If you do not agree to this Privacy Policy, you may not access or otherwise use the Site. We reserve the right, at our discretion, to change, modify, add or remove portions of this Privacy Policy from time to time and such changes shall be posted on the Site. We encourage you to periodically review the Site for the latest information on our privacy practices.
                        </Typography>
                        <Typography paragraph={true} gutterBottom>
                        The Site, together with all its content, is owned or controlled by Scicarta, Inc., a corporation organized in the State of Delaware with its office at 585 Massachusetts Avenue, 4th Floor, Cambridge, Massachusetts 02139, USA.
                        </Typography>

                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>PERSONALLY IDENTIFIABLE INFORMATION THAT WE COLLECT</Typography>
                            <Typography paragraph={true} gutterBottom>
                            To provide the Site, we may collect and process certain personal information that you voluntarily submit to us, such as through our contact pages, information request pages, by email, by seeking employment with us, or some other means. We take care to keep this information secure and prevent any unauthorized access or unlawful use of it as described in the “Security” section below.
                            </Typography>
                            <Typography paragraph={true} gutterBottom>
                            The personal information you may provide to us could include your name, address, date of birth, gender, email addresses and/or mobile phone numbers.
                            </Typography>

                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>NON-PERSONAL OR AGGREGATE INFORMATION THAT WE COLLECT</Typography>
                            <Typography paragraph={true} gutterBottom>
                            When you visit the Site, we may automatically collect certain non-identifying information about you, such as the type of browser or device operating system you use, the domain name of the website from which you linked to us, the amount of time you spend on the Site and the pages on the Site that you view. We may also aggregate information collected from our users.
                            </Typography>
                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>INFORMATION USAGE</Typography>
                            <Typography paragraph={true} gutterBottom>
                            The information we collect about you or you provide to us will be used to provide you with information and any other services associated with them.
                            </Typography>
                            <Typography paragraph={true} gutterBottom>
                                In addition, we may use your personal information:
                                <ul>
                                    <li>To respond to your direct queries.</li>
                                    <li>To operate the Site.</li>
                                    <li>To add you to our mailing lists and to send you emails from time to time.</li>
                                    <li>To fulfill any other purpose for which you provide it.</li>
                                    <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.</li>
                                </ul>
                        You may withdraw your consent to our use of your personal information at any time by contacting us at <Link href={"mailto:info@scicarta.com"} underline={"hover"}>info@scicarta.com</Link>
                            </Typography>
                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>INFORMATION SHARING</Typography>
                            <Typography paragraph={true} gutterBottom>
                            We may disclose and market aggregated information about users of our Site, and aggregated information collected via our Site, that does not identify any individual, without restriction.
                            </Typography>
                            <Typography paragraph={true} gutterBottom>
                             We will only share your personal information as described below, unless you have specifically consented to another type of use, either at the time the personal information is collected from you or through some other form of consent from you.
                            </Typography>
                            <Typography paragraph={true} gutterBottom>
                                We may disclose your personal information:

                                <ul>
                                    <li>To our subsidiaries and affiliates.</li>
                                    <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
                                    <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Scicarta's assets, whether as a going concern or as part of a bankruptcy, liquidation, or similar proceeding, in which personal information held by Scicarta about our users is among the assets transferred.</li>
                                    <li>To other third parties, to allow them to market their products or services to you if you have not opted out of these disclosures. We contractually require these third parties to keep your personal information confidential and use it only for the purposes for which we disclose it to them. For more information, see the “Opt-Out Process” section below.</li>
                                    <li>To fulfill any purpose for which you provide it.</li>
                                    <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                                    <li>To enforce or apply our contract terms and conditions, including for billing and collection purposes.</li>
                                    <li>To investigate suspected fraud, harassment or other violations of any law, rule or regulation, or the policies for the Site.</li>
                                </ul>
                            </Typography>
                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>COOKIES</Typography>
                            <Typography paragraph={true} gutterBottom>
                                We may place small data files on your device or device hard drive known as “cookies” or a similar type of file (such as clear gifs, web beacons, tags, etc.) for the purpose of facilitating and enhancing your communication and interaction with the Site. Many websites use cookies for these purposes. Cookies are also used to collect general usage and volume statistical information. We use this information internally, to help us maintain and enhance the efficiency and usefulness of the Site. We may also use and place cookies on your device from our third party service providers in connection with the Site, such as an analytics provider that helps us manage and analyze Site usage. We also use session ID cookies and persistent cookies. A session ID cookie expires when you close your browser. Our persistent cookies may remain active on your device for up to 1-3 years (depending upon the purpose of the cookie) or until you delete them manually. We set a persistent cookie to enable us to track and target the interests of users to enhance the services we provide. Cookies and similar items are not used by us to automatically retrieve personal information from your device without your knowledge. If you do not wish to receive cookies or want to restrict them or flush them from your device, you may do so by changing the settings on your computer or browser accordingly, in which case you may still use our Site, but it may interfere with some of its functionality.
                            </Typography>
                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>CLICKSTREAM DATA</Typography>
                            <Typography paragraph={true} gutterBottom>
                                As you use the Internet, a trail of electronic information is left at each website you visit. This information, which is sometimes referred to as “clickstream data,” can be collected and stored by a website’s server. Clickstream data can tell us the type of device and browsing software you use and the address of the website from which you linked to the Site. We may collect and use clickstream data to anonymously determine how much time visitors spend on websites, how visitors navigate throughout websites and how we may tailor our Site to better meet consumer needs. This information will be used to improve our Site. Any collection or use of clickstream data will be anonymous, and will not intentionally contain any personal data.
                            </Typography>

                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>SECURITY</Typography>
                            <Typography paragraph={true} gutterBottom>
                                We have implemented technical and organizational measures, appropriate to the risk, to protect your personal information against accidental or unlawful destruction, loss or alteration and unauthorized disclosure or access. However, due to the inherent open nature of the Internet, we cannot ensure or warrant the security of any information provided online.
                            </Typography>

                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>OTHER SITES</Typography>
                            <Typography paragraph={true} gutterBottom>
                                As a convenience to you, we may provide links to third party websites from within the Site. We are not responsible for the privacy practices or content of these third party sites, and by providing a link we are not endorsing or promoting such third party sites. When you link away from our Site, you do so at your own risk. We encourage you to read the policies and terms of every website you visit.
                            </Typography>

                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>INFORMATION RELATING TO CHILDREN</Typography>
                            <Typography paragraph={true} gutterBottom>
                            Our Site is designed for those 13 years of age and older. We do not knowingly collect information from anyone under the age of 13. If we are made aware that we have received such information, or any information in violation of this Privacy Policy, we will use reasonable efforts to locate and remove that information from our records.
                            </Typography>

                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>COMMUNICATIONS WITH SCICARTA</Typography>
                            <Typography paragraph={true} gutterBottom>
                            By providing your email address to us, you expressly consent to receive emails from us. We may use email to communicate with you, to send information that you have requested or to send information about other services provided by us, provided that, we will not give your email address to another party to promote their products or services directly to you except as set forth in this Privacy Policy.
                            </Typography>

                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>DO NOT TRACK SIGNALS</Typography>
                            <Typography paragraph={true} gutterBottom>
                            Please note that we do not respond to or honor “do not track” signals or similar mechanisms transmitted by web browsers.
                            </Typography>

                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>YOUR CALIFORNIA PRIVACY RIGHTS</Typography>
                            <Typography paragraph={true} gutterBottom>
                                California law permits customers in California to request certain details about how their information is shared with third parties for those third parties’ own direct marketing purposes. If you are a California resident, you may request such information from us by contacting us by email at <Link href={"mailto:info@scicarta.com"} underline={"hover"}>info@scicarta.com</Link>. Any such request must include "California Privacy Rights Request" in the first line of the description and include your name, street address, city, state, and zip code. Please note that we are only required to respond to one request per customer each year.
                            </Typography>

                        <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>QUESTIONS AND COMMENTS</Typography>
                            <Typography paragraph={true} gutterBottom>
                            If you have any queries or comments about this Privacy Policy or our privacy practices, or wish to resume receiving information which you may have previously opted-out of receiving, please contact us at <Link href={"mailto:info@scicarta.com"} underline={"hover"}>info@scicarta.com</Link>.
                            </Typography>
                            </Typography>
                    </Box>
                </DialogContent>
            </ModalDialog>
        </div>
    );
}
