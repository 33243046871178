import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useKeycloak } from '@react-keycloak/web';
import {Divider, Skeleton} from "@mui/material";
import {getUsername} from "../../store/selectors";
import {useSelector} from "react-redux";

export const ProfileDropdown: React.FunctionComponent = () => {

    const username = useSelector(getUsername);
    const {keycloak} = useKeycloak();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem >
                    <Typography textAlign="center">{username ?? <Skeleton variant="text" sx={{ fontSize: '1rem' }}/>}</Typography>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => keycloak.logout()}>
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};
