import * as React from 'react';
import { Typography} from '@mui/material';
import {useHighlighting} from "../highlighter/HighLightText";
import {StyledChunks, StyledWords} from "../highlighter/HighLightReconciliation";
import {useMemo} from "react";

interface PaperViewerParagraphProps {
    title: string;
    content: string;
    isInline?: boolean;
    styledChunks?: ReadonlyArray<StyledChunks>;
    styledWords?: ReadonlyArray<StyledWords>;
}

export const PaperViewerParagraph: React.FunctionComponent<PaperViewerParagraphProps> = (props) => {
    const component = props.isInline ? 'span' : 'p';
    const content = useHighlighting(props.content, {
        chunks: props.styledChunks,
        words: props.styledWords
    });

    const formattedContent = useMemo(() => {
        return truncateFirstLineFeed(replaceFormFeedWithDetailedDescription(content)).replaceAll(
            '\n', '<br/>');
    }, [content]);

    function truncateFirstLineFeed(text: string): string {
      if (text.startsWith('\n')) {
        return text.substring(1);
      }
      return text;
    }

       function replaceFormFeedWithDetailedDescription(text: string): string {
        return  text.replace('\r\n\r\n\f\r\n','<br/><b>Detailed Description</b><br/>')
    }

    return (
        <Typography component="div" align={"justify"} sx={{ fontSize:12}}>
            <Typography component="strong" sx={{ color:"#555",fontWeight: 600,fontSize:12}}>
                {props.title}
            </Typography>
            <Typography component={component} sx={{ fontSize:12}} > <span dangerouslySetInnerHTML={{__html: formattedContent}} /> </Typography>
        </Typography>
    );
};
