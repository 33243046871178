export enum FilterElementType {
    STRING,
    ENTITY
}

type FilterElementBase<T extends FilterElementType> = {
    type: T;
    id: string;
    displayName: string;
}

type FilterElementEntity = FilterElementBase<FilterElementType.ENTITY> & {
    isLoading: boolean;
}

type FilterElementString = FilterElementBase<FilterElementType.STRING>

export type FilterElement = FilterElementString | FilterElementEntity;

export type StoredFilterElement = FilterElement & {
    color: number;
}

export interface AppQuery<F extends FilterElement = FilterElement> {
    query: string;
    maxArticles: number;
    filters: ReadonlyArray<F>;
    sources: ReadonlyArray<string>;
}
