import { important } from 'csx';
import * as React from 'react';
import TreeMenu, {MatchSearchFunction, TreeNodeInArray} from 'react-simple-tree-menu';
import {style} from 'typestyle';
import {IconButton, InputAdornment, Typography} from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import {Item} from "react-simple-tree-menu/dist/TreeMenu/walk";
import {TreeNodeScicarta} from "./Types";
import {MenuItem} from "./MenuItem";
import {TreeFilter, TreeFilterElement, TreeFilterType} from "../../types/TreeFilter";
import {FilterElement, FilterElementType} from "../../types/AppQuery";
import {abbreviate} from "../../utils/Number";
import {Fill, Top} from "react-spaces";

const initialOpenNodes = [
    'Industry-group',
    'Industry-group/Company-group'
];

const inputClassName = style({
    marginBottom: important('10px')
});


const KEY_SEPARATOR = '~~';

export interface EntitiesMenuProps {
    filter: TreeFilter;
    selected: Readonly<Record<string, FilterElement>>;
    add: (element: FilterElement) => void;
    remove: (filterId: string) => void;
}

const computeKey = (key: string, parent?: string): string => {
    return parent ? parent + KEY_SEPARATOR + key : key;
};

const expandParent = (value: TreeNodeInArray, parent: TreeNodeInArray): TreeNodeInArray => {
    value.parent = parent.parent ? parent.parent + KEY_SEPARATOR + parent.key : parent.key;
    return value;
};

const searchContent = (search: string, label: string) => {
    return label.toLowerCase().includes(search);
};

const getNode = (key: string, nodes: Array<TreeNodeInArray>) => nodes.find(n => n.key === key);

const getNodeFromPath = (pathString: string, data: Array<TreeNodeInArray>) => {
    const path = pathString.split(KEY_SEPARATOR);
    let node = getNode(path[0], data);

    for (let i = 1; i < path.length; ++i) {
        if (!node || !node?.nodes) {
            throw new Error('Invalid node found');
        }

        node = getNode(path[i], node.nodes);
    }

    if (!node) {
        throw new Error('Not found found');
    }

    return node;
};

const countReducer = (count: number, value: TreeFilterElement): number => {
    if (value.type === TreeFilterType.ITEM) {
        return count + value.foundCount;
    } else if (value.type === 'group') {
        return count + value.content.reduce(countReducer, 0);
    } else {
        throw new Error('Invalid value found on entities' + JSON.stringify(value));
    }
};


const entityMap = (value: TreeFilterElement, parent?: TreeNodeInArray): TreeNodeInArray & TreeNodeScicarta => {
    if (value.type === TreeFilterType.ITEM) {
        const entity = {
            key: value.id,
            label: value.name,
            type: value.type,
            count: value.foundCount,
            words: value.foundAlias,
            id: value.id,
        };

        if (parent) {
            expandParent(entity, parent);
        }

        return entity;

    } else if (value.type === TreeFilterType.GROUP) {
        const group: TreeNodeInArray & TreeNodeScicarta = {
            key: `${value.name}-group`,
            label: value.name,
            type: value.type,
            nodes: [],
            count: value.content.reduce(countReducer, 0)
        };

        if (parent) {
            expandParent(group, parent);
        }

        if (group.nodes !== undefined) {
            group.nodes.push(...value.content.map(e => entityMap(e, group)));
        }

        return group;
    } else {
        throw new Error('Invalid value found on entities' + JSON.stringify(value));
    }
};

interface ClearInputProps {
    search: (query: string) => void;
    showCloseSearch: boolean;
}

const ClearInput: React.FunctionComponent<ClearInputProps> = props => (
    <InputAdornment position="end">
        <IconButton
            aria-label="close"
            onClick={() => props.search('')}
            edge="end"
        >
            {props.showCloseSearch ? <CloseIcon/> : ''}
        </IconButton>
    </InputAdornment>
);

export const TreeMenuFilter: React.FunctionComponent<EntitiesMenuProps> = (props) => {
    const [searchValue, setSearchValues] = React.useState('');

    const onItemClick = React.useCallback((element:Item) => {
        if (element.hasNodes) {
            return;
        }

        const isInSelected = element.id in props.selected;

        let filterType = FilterElementType.ENTITY;
        // Todo: Rework this logic - Also is probably worth to rename this to be something else than Entities*
        if  (element.parent.startsWith('Industry-group') || element.parent.startsWith('Authors-group')) {
            filterType = FilterElementType.STRING;
        }

        if (isInSelected) {
            props.remove(element.id);
        } else {
            props.add({
                id: element.id,
                type: filterType,
                displayName: element.label,
                isLoading: false
            });
        }
    }, [ props.add, props.remove, props.selected ]);

    const data: Array<TreeNodeInArray> = React.useMemo(() => {
        return props.filter.content.map(c => entityMap(c));
    }, [ props.filter ]);

    const count = React.useMemo(() => abbreviate(props.filter.content.reduce(countReducer, 0)), [ props.filter ]);

    let cacheResults: Record<string, boolean> = {};
    const matchSearch: MatchSearchFunction = (matchSearchFunctionProps) => {

        const recursiveSearch = (node: TreeNodeInArray, search: string): boolean => {
            const key = computeKey(node.key, node.parent);
            if (key in cacheResults) {
                return cacheResults[key];
            }

            let result = searchContent(search, node.label);

            if (node.nodes) {
                for (const childNode of node.nodes) {
                    result = result || recursiveSearch(childNode, search);
                }
            }

            cacheResults[key] = result;

            return result;
        };

        const searchTerm = matchSearchFunctionProps.searchTerm.toLowerCase().trim();
        const key = computeKey(matchSearchFunctionProps.key, matchSearchFunctionProps.parent);

        if (key in cacheResults) {
            return cacheResults[key];
        }

        const node = getNodeFromPath(key, data);
        cacheResults[key] = recursiveSearch(node, searchTerm);

        return cacheResults[key];
    };


    return (
        <>
            <Top size={"35px"} order={1} style={{ paddingTop: '15px', paddingLeft:'15px'}}>
                <Typography variant="body1" >
                    <b>Search concepts:</b> { count }
                </Typography>
            </Top>
            <TreeMenu
                data={ data }
                hasSearch={ true }
                disableKeyboard={ true }
                onClickItem={ onItemClick }
                initialOpenNodes={ initialOpenNodes }
                matchSearch={ matchSearch }
            >
                {({ search, items }) => (
                    <div className={"sidebar"}>
                        <Top size={"45px"} order={2} style={{ paddingLeft: '13px'}}>
                            <FormControl className={inputClassName} sx={{  width: '25ch' }} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    placeholder="Concept live search"
                                    size="small"
                                    value={ searchValue }
                                    onChange={ e => {
                                            const value = e.currentTarget.value;
                                            setSearchValues(value);
                                            if (search) {
                                                cacheResults = {};
                                                search(value);
                                            }
                                    } }
                                    endAdornment={<ClearInput search={() => {
                                        setSearchValues('');
                                        if (search) {
                                            cacheResults = {};
                                            search('');
                                        }
                                    }} showCloseSearch={searchValue != ''}/>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                            </FormControl>
                        </Top>
                        <Fill scrollable={true} style={{ paddingLeft: '10px', paddingBottom:'10px'}}>
                            <ul>
                                {items.map(({ key, ...internalProps }) => {
                                    const isSelected = internalProps.id in props.selected;
                                    return (
                                        <MenuItem key={ key } selected={ isSelected }  { ...internalProps } />
                                    );
                                })}
                            </ul>
                        </Fill>
                    </div>
                )}
            </TreeMenu>
        </>
    );
};
