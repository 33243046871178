import React, {FunctionComponent} from 'react';
import {useSelector} from "react-redux";
import {getFilters, getMaxArticles, getQuery, getSelectedSources} from "../../store/selectors";
import {buildShareAppQueryUrl} from "../../utils/ShareQuery";
import Config from "../../Config";
import {ShareDialog} from "../../components/shareDialog/ShareDialog";

export interface ShareDialogProps {
    isOpen: boolean;
    handleClose: () => void;
}
export const ShareDialogPage: FunctionComponent<ShareDialogProps> = ({isOpen, handleClose}) => {
    const query = useSelector(getQuery);
    const maxArticles = useSelector(getMaxArticles);
    const filters = useSelector(getFilters);
    const sources = useSelector(getSelectedSources);

    const url = buildShareAppQueryUrl({
        query,
        maxArticles,
        filters: Object.values(filters),
        sources: [ ...sources ]
    }, Config.baseUrl);

    return <ShareDialog
        link={ url }
        isOpen={ isOpen }
        handleClose={ handleClose }
    />;
}
