import {StoreActionType, StoreState} from "../store";
import {StoreActionReducer} from "../reducer";

export interface StoreActionStartSession {
    type: StoreActionType.START_SESSION;
}

export const startSessionAction = (): StoreActionStartSession => ({
    type: StoreActionType.START_SESSION,
});

export const startSessionReducer: StoreActionReducer<StoreActionType.START_SESSION> = (state, _action): StoreState | void => {
    state.sessionId = (new Date()).getTime().toString(36);
};
