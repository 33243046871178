import {useQuery} from "react-query";
import axios from "axios";
import Config from "../Config";
import {Entity} from "../types/Entities";
import {useStoreDispatch} from "../store/store";
import {addEntitiesAction} from "../store/actions/entities/addEntity";
import {TreeFilter, TreeFilterElement, TreeFilterType} from "../types/TreeFilter";
import {setTreeFilterAction} from "../store/actions/treeFilter/setTreeFilter";

interface SidebarRequest {
    query: string;
    maxArticles: number;
    sources: ReadonlySet<string>;
}

interface SidebarResponse {

}

const buildFilterTree = (container: Array<TreeFilterElement>, rawEntities: object, entityMap: Record<string, string>): void => {
    Object.entries(rawEntities).forEach((([name, rawContent]) => {
        const element: TreeFilterElement = {
            type: TreeFilterType.GROUP,
            name,
            content: []
        };

        if (Array.isArray(rawContent)) {
            element.content.push(...(rawContent.map((item): TreeFilterElement => {
                const id = item.conceptId as string;
                return {
                    id,
                    type: TreeFilterType.ITEM,
                    name: entityMap[id],
                    foundCount: item.count,
                    foundAlias: item.foundNames
                };
            })));
        } else {
            buildFilterTree(element.content, rawContent, entityMap);
        }

        container.push(element);
    }));
}

// api/sidebar?&search_term=Alpha-synuclein&sources=pubmed,clinical_trials
export const useSidebar = (request?: SidebarRequest) => {
    const dispatch = useStoreDispatch();

    const { isLoading, isRefetching, error, data, refetch } = useQuery({
        enabled: Config.updateGraphOnType ?  !!request?.query : false,
        retry: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        queryKey: ['sidebar', request?.query ? {
            query: request.query,
            maxArticles: request.maxArticles,
            sources: [...request.sources].join(','),
        } : undefined],
        queryFn: async (): Promise<void> => {
            if (!request?.query) {
                throw new Error('Invalid path. This is likely a bug');
            }

            const queryParams: Array<string> = [
                `search_term=${request.query}`,
                `sources=${[...request.sources].join(',')}`,
                `article_max=${request.maxArticles}`,
            ];

            const response = await axios.get(
                `api/sidebar?&${queryParams.join('&')}`
            );

            // extract entities
            let entities: Array<Entity> = [];
            if (response.data?.canonicalNames) {
                const entityMap = (response.data?.canonicalNames as Record<string, string>);
                entities = Object.entries(entityMap).map(([id, displayName]) => ({
                    id,
                    displayName
                }));
                dispatch(addEntitiesAction(entities));
            }

            // extract entity navigation
            if (response.data?.entities && response.data?.canonicalNames) {
                const entityTreeList =  response.data?.entities;
                const entityMap = response.data?.canonicalNames;
                const navigation: TreeFilter = {
                    content: []
                };
                buildFilterTree(navigation.content, entityTreeList, entityMap)
                dispatch(setTreeFilterAction(navigation));
            }
        }
    });

    return {
        isLoading: isLoading || isRefetching,
        error,
        data,
        refetch
    };
}
