import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";

export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    return (
        <>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            {/*<Box sx={{ minWidth: 35 }}>*/}
            {/*    <Typography*/}
            {/*        variant="body2"*/}
            {/*        sx={{ color: 'text.secondary' }}*/}
            {/*    >{`${Math.round(props.value)}%`}</Typography>*/}
            {/*</Box>*/}
        </>
    );
}
