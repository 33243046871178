import * as React from "react";
import { Grid, Typography, Button } from "@mui/material";
import {Link} from "react-router-dom";


export const Footer: React.FunctionComponent = () => {
    return (
        <Grid container
              //position="fixed"
              direction="row"
              sx={{
                  height:"100%",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  bgcolor: "#E0E0E0",
                  paddingLeft: '10px'
              }}>
            <Grid item xs={4} justifyContent="flex-end">
                <Typography sx={{
                    paddingTop: '6px',
                    paddingBottom: '6px',
                }}>© Scicarta Inc, 2024</Typography>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} alignItems={"flex-end"} justifyContent="flex-end" >
                <Button sx={{textTransform: 'none'}} component={Link} color="inherit" to="contact-us" >Contact Us</Button>
                <Button sx={{textTransform: 'none'}} component={Link} color="inherit" to="privacy-policy">Privacy Policy</Button>
                <Button sx={{textTransform: 'none'}} component={Link} color="inherit" to="terms-of-use">Terms of Use</Button>
            </Grid>
        </Grid>
    );
};
