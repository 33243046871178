import * as React from 'react';
import './App.css';
import "@yaireo/tagify/dist/tagify.css";
import { MainLayout } from "./pages/main/MainLayout";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {Provider} from "react-redux";
import {getReduxStore} from "./store/store"
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { theme} from "./ScicartaTheme";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    createRoutesFromElements,
} from "react-router-dom";
import {ModalCustom} from "./components/modals/ModalCustom";
import {useKeycloak} from "@react-keycloak/web";
import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {UserInfo} from "./types/UserInfo";
import LogRocket from "logrocket";
import Config from "./Config";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
});

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<MainLayout />}>
            <Route path="/:modal" element={<ModalCustom />} />
        </Route>
    )
);

interface KeycloakInitProps {
    setUserInfo: (userInfo: UserInfo) => void;
}

const KeycloakInit: FunctionComponent<KeycloakInitProps> = ({setUserInfo}) => {
    const {keycloak, initialized} = useKeycloak();

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            keycloak.loadUserInfo().then(userInfo => setUserInfo(userInfo as UserInfo));
        }
    }, [initialized, keycloak.authenticated]);

    return null;
}

function App() {
    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(() => {
        if (!Config.keycloakEnabled) {
            return {
                email: 'anynymous',
                scicarta: {
                    source: ['pubmed', 'clinical-trials']
                }
            }
        }

        return undefined;
    });


    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            LogRocket.init('bckcct/scicarta');
        }
    }, []);

    const reduxStore = useMemo(() => {
        if (!userInfo) {
            return undefined;
        }

        return getReduxStore(userInfo)
    }, [userInfo]);

    const email = userInfo?.email;

    useEffect(() => {
        if (email) {
            if (process.env.NODE_ENV === 'production') {
                LogRocket.identify(email);
            }
        }
    }, [email, reduxStore]);

    if (!reduxStore) {
        return <>
            <KeycloakInit
                setUserInfo={setUserInfo}
            />
            <div>Loading...</div>
        </>;
    }

    return (
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={ reduxStore }>
              <QueryClientProvider client={queryClient}>
                  <RouterProvider router={router} />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
          </Provider>
      </ThemeProvider>
    );
}

export default App;
