import {AppQuery, FilterElementType} from "./types/AppQuery";

const apiBaseUrl = '/api';

const withBaseUrl = (path: string) => `${apiBaseUrl}/${path}`;

const getBaseUrl = () => {
    const url = new URL(window.location.href);
    return `${url.protocol}//${url.host}`;
};

const Config = {
    appId: 'pubskape',
    keycloakEnabled: false,
    updateGraphOnType: false,
    baseUrl: getBaseUrl(),
    apis: {
        urls: {
            base: apiBaseUrl,
            plot: withBaseUrl('plot'),
            enrichments: withBaseUrl('enrichments'),
            papersCount: withBaseUrl('get_papers_count')
        }
    },
    appQueries: [
        {
            query: 'VEGF PD1 combination',
            maxArticles: 100,
            filters: [
                {
                    type: FilterElementType.STRING,
                    id: 'bispecific',
                    displayName: 'bispecific'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'akeso',
                    displayName: 'akeso'
                },
                {
                    type: FilterElementType.ENTITY,
                    id: 'C3658706',
                    displayName: 'Non-small cell lung cancer carcinoma',
                    isLoading: false
                },
                {
                    type: FilterElementType.ENTITY,
                    id: 'C0007131',
                    displayName: 'pembrolizumab',
                    isLoading: false
                },
            ],
            sources: ['pubmed', 'clinical-trials']
        },
        {
            query: 'CD19 Lupus',
            maxArticles: 100,
            filters: [
                {
                    type: FilterElementType.STRING,
                    id: 'georg schett',
                    displayName: 'Georg Schett'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'remission',
                    displayName: 'remission'
                },
    {
                    type: FilterElementType.STRING,
                    id: 'cabaletta bio',
                    displayName: 'Cabaletta Bio'
                },
    {
                    type: FilterElementType.STRING,
                    id: 'kyverna therapeutics',
                    displayName: 'Kyverna Therapeutics'
                },
                {
                    type: FilterElementType.ENTITY,
                    id: 'C0393022',
                    displayName: 'rituximab',
                    isLoading: false
                }
            ],
            sources: ['pubmed', 'clinical-trials']
        },
        {
            query: 'hif2alpha',
            maxArticles: 500,
            filters: [
                {
                    type: FilterElementType.STRING,
                    id: 'william g kaelin',
                    displayName: 'William G Kaelin'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'peloton therapeutics',
                    displayName: 'Peloton Therapeutics'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'merck',
                    displayName: 'Merck'
                },
                {
                    type: FilterElementType.ENTITY,
                    id: 'C0694897',
                    displayName: 'VHL gene',
                    isLoading: false
                },
                {
                    type: FilterElementType.ENTITY,
                    id: 'C0007134',
                    displayName: 'Renal Cell Carcinoma',
                    isLoading: false
                },
            ],
            sources: ['pubmed']
        },
        {
            query: 'pseudouridine mRNA',
            maxArticles: 500,
            filters: [
                {
                    type: FilterElementType.STRING,
                    id: 'Drew Weissman',
                    displayName: 'Drew Weissman'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'Katalin Karikó',
                    displayName: 'Katalin Karikó'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'biontech',
                    displayName: 'BioNTech'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'moderna',
                    displayName: 'Moderna'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'vaccine',
                    displayName: 'vaccine'
                },
            ],
            sources: ['pubmed']
        },
        {
            query: 'undruggable',
            maxArticles: 500,
            filters: [
                {
                    type: FilterElementType.STRING,
                    id: 'Craig M Crews',
                    displayName: 'Craig M Crews'
                },
                {
                    type: FilterElementType.STRING,
                    id: 'protac',
                    displayName: 'PROTAC'
                },
                {
                    type: FilterElementType.ENTITY,
                    id: 'C0034678',
                    displayName: 'ras Oncogene',
                    isLoading: false
                }
            ],
            sources: ['pubmed']
        },
        {
            query: 'hurler syndrome "gene therapy"',
            maxArticles: 100,
            filters: [],
            sources: ['pubmed']
        }
    ].map(x => ({...x, filters: x.filters.map(f => ({...f, id: f.type === FilterElementType.STRING ? f.id.toLowerCase() : f.id}))})) as ReadonlyArray<AppQuery>
};

const ReadOnlyConfig: Readonly<typeof Config> = Config;
export default ReadOnlyConfig;
