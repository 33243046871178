import * as React from "react";
import {IconButton} from "@mui/material";
import {useState} from "react";
import {CenterType, Fill, Left, LeftResizable} from "react-spaces";
import {TreeMenuFilter} from "../../components/treeMenuFilter/TreeMenuFilter";
import {FilterElement} from "../../types/AppQuery";
import {addFilterAction} from "../../store/actions/addFilter";
import {removeFilterAction} from "../../store/actions/removeFilter";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {useSelector} from "react-redux";
import {getFilters, getFiltersAsRecord, getTreeFilter} from "../../store/selectors";
import {useStoreDispatch} from "../../store/store";

export const SideBar: React.FunctionComponent = () => {
    const filters = useSelector(getFiltersAsRecord);
    const treeFilter = useSelector(getTreeFilter);
    const dispatch = useStoreDispatch();
    const [ sidebarExpanded, setSidebarExpanded ] = useState(true);

    return (
        <>
            <LeftResizable size={sidebarExpanded ? 240 : 0} trackSize={true} maximumSize={340} minimumSize={240} order={1} style={{
                padding: '15px',
                borderRight: "1px solid #ccc",
            }}>
                <TreeMenuFilter
                    filter={ treeFilter }
                    selected={ filters }
                    add={ (filter: FilterElement) => dispatch(addFilterAction(filter))}
                    remove={ (id: string) => dispatch(removeFilterAction(id))}
                />
            </LeftResizable>
            <Left size={"20px"} order={2}>
                <Fill centerContent={CenterType.HorizontalVertical} >
                    <IconButton color="primary" size={"small"}
                                onClick={() => setSidebarExpanded(prev => !prev)}
                    >
                        {(sidebarExpanded) ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon/>}

                    </IconButton>
                </Fill>
            </Left>
        </>
    );
};
