import * as React from 'react';
import {PaperListInformation} from "../PaperListInformation";
import {PaperListInformationDetail} from "../PaperListInformationDetail";
import {DocumentClinicalTrial, ScicartaDocument} from "../../../types/DocumentData";

interface PaperSelectedInformationClinicalTrialProps {
    document : DocumentClinicalTrial;
    getTextByItem: (item: ScicartaDocument)=>string;
    onDeleteDocument: (documentId: string) => void;
}

const dateOptions: Intl.DateTimeFormatOptions = {month: 'long', year: 'numeric'};

export const PaperSelectedInformationClinicalTrial: React.FunctionComponent<PaperSelectedInformationClinicalTrialProps> = (props) => {

    return (
        <>
            <PaperListInformation
                title={props.document.title}
                link={"https://clinicaltrials.gov/ct2/show/" + props.document.id}
                textToCopy={props.getTextByItem(props.document)}
                documentId={props.document.id}
                onDeleteDocument={props.onDeleteDocument}
            >
                <PaperListInformationDetail
                    title={props.document.sponsor.map((text) => text ).join(', ')}
                    content={""}/>
                <PaperListInformationDetail
                    title={props.document.journal + " - " + props.document.id + " - " +
                        new Date(props.document.date).toLocaleDateString('en-US', dateOptions)}
                    content={""}/>
            </PaperListInformation>
        </>
    );
};
