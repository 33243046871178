import {StoreActionType, StoreState} from "../store";
import {StoreActionReducer} from "../reducer";

export interface StoreActionSetMaxArticles {
    type: StoreActionType.SET_MAX_ARTICLES;
    maxArticles: number;
}

export const setMaxArticlesAction = (maxArticles: number): StoreActionSetMaxArticles => ({
    type: StoreActionType.SET_MAX_ARTICLES,
    maxArticles
});

export const setMaxArticlesReducer: StoreActionReducer<StoreActionType.SET_MAX_ARTICLES> = (state, action): StoreState | void => {
    state.maxArticles = action.maxArticles;
};
