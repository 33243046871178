import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Fill, Top, TopResizable, CenterType, Right} from "react-spaces";
import {Graph} from "./Graph";
import {PlotDataResponse} from "../../types/PlotData";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DragHandle from '@mui/icons-material/DragHandle';
import {useState} from "react";
import {useWindowSize} from "react-use";
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import {getDocumentCount, getMaxArticles, getQuery, getSessionId} from "../../store/selectors";

interface GraphContainerProps {
    plotData?: PlotDataResponse;
}

const GraphContainerLegend: React.FunctionComponent = () => {
    const paperCount = useSelector(getDocumentCount)
    const search = useSelector(getQuery);
    const maxArticles = useSelector(getMaxArticles);
    const lastWordLength = search.split(' ').pop()?.length ?? 0;
    const isLastWordMoreThanTwoCharacters = lastWordLength > 2;

    if (paperCount === 0 && isLastWordMoreThanTwoCharacters) { // if split(" ")[-1] has more than two characters
        return <span><strong>
            We couldn't find any document matching <span style={{color: 'brown'}}>{search}</span>
        </strong></span>
    }
    else if (paperCount as number > maxArticles && isLastWordMoreThanTwoCharacters) {
    // return <span>Only analyzing the most recent {maxArticles} papers and {maxArticles} trials out of the {paperCount} results </span>
    return <span>Only analyzing the most recent papers and trials out of the {paperCount} results </span>
    }
    return <span></span>
}

export const GraphContainer: React.FunctionComponent<GraphContainerProps> = props => {
    const [ sidebarExpanded, setSidebarExpanded ] = useState(true);
    const { height} = useWindowSize();
    const sessionId = useSelector(getSessionId);

    const graph = {
        borderTop: "2px solid #ccc",
        borderBottom: "2px solid #ccc",
        borderLeft: "1px solid #ccc",
        borderRight: "1px solid #ccc",
        background: "white",
    };

    const graphTop = {
        borderLeft: "2px solid #ccc",
        borderRight: "2px solid #ccc",
        borderBottom: "2px solid #ccc",
        background: "white",
    };

    return (
        <TopResizable
            size={sidebarExpanded ? "40%" : "32px"}
            style={graph}
            order={3}
            trackSize={true}
            maximumSize={height/2}
            minimumSize={Math.round(0.2 * height)}
            handleRender={(props) => {
                return <div {...props} style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <DragHandle style={{
                        position: 'absolute',
                        transform: 'translateY(-35%)'
                    }} />
                </div>
            }}
        >
            <Top size="30px" style={graphTop}>
                <Fill centerContent={CenterType.Vertical} >
                    <Box sx={{marginLeft:"16px",color:"#87868a!important"}}>
                        <Typography variant="subtitle1" >
                          <GraphContainerLegend />
                        </Typography>
                    </Box>
                </Fill>
                <Right
                    size={25}
                    centerContent={CenterType.HorizontalVertical}
                    onClick={() => setSidebarExpanded(prev => !prev)}>
                    {sidebarExpanded? <ArrowDropDownIcon sx={{color:"#87868a!important"}}/>:<ArrowDropUpIcon sx={{color:"#87868a!important"}}/> }
                </Right>
            </Top>
            <Fill trackSize>
                <Graph
                    key={sessionId}
                    plotData={ props.plotData }
                />
            </Fill>
        </TopResizable>


    );
};
