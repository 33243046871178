import * as React from 'react';
import {
    AnchorType,
    Bottom,
    Custom,
    Fill,
    Top,
    ViewPort
} from "react-spaces";
import {useSelector} from "react-redux";
import {getDocumentCount, getFilters, getMaxArticles, getQuery, getSelectedSources} from "../../store/selectors";
import {usePlotData} from "../../services/PlotData";
import {FilterElementType} from "../../types/AppQuery";
import {Footer} from "../../components/navigation/Footer";
import {PublicationsQuery} from "../../components/filter/PublicationsQuery";
import {Filter} from "../../components/filter/Filter";
import {Header} from "../../components/navigation/Header";
import {GraphContainer} from "../../components/graph/GraphContainer";
import {PaperInformationPage} from "./PaperInformationPage";
import {SideBar} from "./SideBar";
import {ShareDialogPage} from "./ShareDialogPage";
import {useEffect, useState} from "react";
import {useSyncUrl} from "./useSyncUrl";

import { Outlet } from "react-router-dom";
import {usePapersCount} from "../../services/PapersCount";
import Config from "../../Config";
import {PlotDataRequest} from "../../types/PlotData";
import {useSidebar} from "../../services/Sidebar";

export const MainLayout: React.FunctionComponent = () => {
    const mainFill = {
        margin:"18px",
    };

    const [displayShareQuery, setDisplayShareQuery] = useState(false);

    const query = useSelector(getQuery);
    const filters = useSelector(getFilters);
    const sources = useSelector(getSelectedSources);
    const paperCount = useSelector(getDocumentCount);
    const maxArticles = useSelector(getMaxArticles);

    const firstLoad = useSyncUrl();

    usePapersCount((!query) ? undefined : {
        query,
        sources
    });

    // Create a function to handle this logic
    let request : PlotDataRequest | undefined = undefined;
    if (!Config.updateGraphOnType || (paperCount && paperCount > 0)) {
        request = {
            query: query,
            maxArticles,
            filters: {
                keywords: Object.values(filters).filter(f => f.type === FilterElementType.STRING).map(f => f.id),
                entities: Object.values(filters).filter(f => f.type === FilterElementType.ENTITY).map(f => f.id),
            },
            sources: sources
        };
    }

    const papersQuery = usePlotData(request);
    const sidebarQuery = useSidebar(request);

    const refreshQuery = React.useCallback(() => {
        void papersQuery.refetch({ cancelRefetch: false});
        void sidebarQuery.refetch({ cancelRefetch: false });
    }, [papersQuery.refetch, papersQuery.refetch]);

    useEffect(() => {
        if (!firstLoad) {
            refreshQuery();
        }
    }, [firstLoad]);

    return (
        <>
            <ViewPort>
                <Top size="30px"  >
                    <Header loadingPercent={papersQuery.loadingPercent} />
                </Top>
                <Fill>
                    <SideBar/>
                    <Fill style={mainFill}>
                        <Custom anchor={AnchorType.Top} anchorSize={55} order={1}>
                            <PublicationsQuery
                                onEnter={refreshQuery}
                            />
                        </Custom>
                        <Filter
                            onSearch={refreshQuery}
                            openShareQuery={() => setDisplayShareQuery(true)}
                            isSearching={papersQuery.isLoading || sidebarQuery.isLoading}
                        />
                        <GraphContainer plotData={ papersQuery.data }/>
                        <PaperInformationPage/>
                    </Fill>
                    <Bottom size="30px">
                        <Footer/>
                    </Bottom>
              </Fill>
            </ViewPort>
            <ShareDialogPage isOpen={displayShareQuery} handleClose={() => setDisplayShareQuery(false)} />
            <Outlet></Outlet>
        </>
    );
};
