import * as React from 'react';
import { AppBar, Typography, Toolbar, Button, Box } from "@mui/material";
import { ProfileDropdown} from "./ProfileDropdown";
import {Link, useLocation} from "react-router-dom";
import Config from "../../Config";
import {LinearProgressWithLabel} from "../Progressbar/LinearProgressWithLabel";

export interface HeaderProps {
    loadingPercent: number | undefined;
}

export const Header: React.FunctionComponent<HeaderProps> = ({loadingPercent}) => {
    const location = useLocation();
   // const { path } = useRouteMatch();

    return (
        <AppBar
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
            <Toolbar variant="dense" sx={{height: '40px'}}>
                <Box component="img"
                     src="https://www.scicarta.org/lib_cqnsrYCrCjGzgKvh/tf7c8vd73v4mjn9w.png"
                     alt="Scicarta Logo"
                     sx={{height: '32px', width: "32px", padding: '1px'}}/>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        display: 'flex',
                        fontWeight: 500,
                        letterSpacing: '.01rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    Scicarta
                </Typography>
                <Box sx={{ display: 'flex'}}>
                    <Typography sx={{ my: 2, color: 'white', display: 'block', marginTop: '', marginBottom: '' }}>
                        <em>skim science quickly</em>
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 1, display: 'flex', marginLeft: 5, marginRight: 5, marginTop: '4px', marginBottom: 'auto' }}>
                    { loadingPercent !== undefined && <LinearProgressWithLabel color={"success"} value={loadingPercent} />}
                </Box>
                <Button
                    color="inherit"
                    onClick={() => window.open('https://www.scicarta.org', '_blank')}>
                    Quickstart
                </Button>
                {/*Leaving below as example code* for modal dialogs/}
                {/*<Button*/}
                {/*    component={Link}*/}
                {/*    color="inherit"*/}
                {/*    state={{ background: location }}*/}
                {/*    to={'/quickstart'}*/}
                {/*>*/}
                {/*    Quickstart*/}
                {/*</Button>*/}
                {/*<Button*/}
                {/*    component={Link}*/}
                {/*    color="inherit"*/}
                {/*    to="help"*/}
                {/*    >*/}
                {/*        Help*/}
                {/*</Button>*/}
                {Config.keycloakEnabled && <ProfileDropdown/>}
            </Toolbar>
        </AppBar>
    );
};
