import {StoreState} from "./store";
import {StoredFilterElement} from "../types/AppQuery";
import {Entity} from "../types/Entities";
import {TreeFilter} from "../types/TreeFilter";
import {Point} from "../types/PlotData";
import {ScicartaDocument} from "../types/DocumentData";

export type StoreSelector<T> = (state: StoreState) => T;

export const getAvailableSources: StoreSelector<ReadonlySet<string>> = (state) => state.availableSources;
export const getSelectedSources: StoreSelector<ReadonlySet<string>> = (state) => state.selectedSources;
export const getQuery: StoreSelector<string> = (state) => state.query;
export const getMaxArticles: StoreSelector<number> = (state) => state.maxArticles;
export const getFilters: StoreSelector<ReadonlyArray<StoredFilterElement>> = (state) => state.filters;
export const getEntities: StoreSelector<Readonly<Record<string, Readonly<Entity>>>> = (state) => state.entities;
export const getTreeFilter: StoreSelector<TreeFilter> = (state) => state.treeFilter;
export const getLastHoveredPoint: StoreSelector<Point|undefined> = (state) => state.graphStatus.lastHoveredPoint;
export const getLastClickedPoint: StoreSelector<Point|undefined> = (state) => state.graphStatus.lastClickedPoint;
export const getDocuments: StoreSelector<Readonly<Record<string, ScicartaDocument>>> = (state) => state.documentsStore.documents;
export const getSelectedDocuments: StoreSelector<Record<string, ScicartaDocument>> = (state) => state.documentsStore.selectedDocuments;
export const getDocumentCount: StoreSelector<number | undefined> = (state) => state.documentCount;
export const getUsername: StoreSelector<string | undefined> = (state) => state.username;
export const getSessionId: StoreSelector<string> = (state) => state.sessionId;

export const getFiltersAsRecord: StoreSelector<Readonly<Record<string, StoredFilterElement>>> = (state) => {
    return Object.fromEntries(
        state.filters.map(f => {
            return [f.id, f];
        })
    );
};
