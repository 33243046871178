import {useMutation} from "react-query";
import axios from "axios";

interface DownloadTxtRequest {
    paperIds: Array<string>;
}

interface DownloadTxtResponse {
    txt: string;
}

export const useDownloadTxt = () => {
    return useMutation({
        mutationKey: ['download-txt'],
        mutationFn: async (request: DownloadTxtRequest): Promise<DownloadTxtResponse> => {
            const response = await axios.post(
                '/api/download-txt',
                request.paperIds
            )
            return {
                txt: response.data
            };
        }
    });
}
