import {AppQuery, StoredFilterElement} from "../../../types/AppQuery";
import {StyledWords} from "../../highlighter/HighLightReconciliation";
import {useMemo} from "react";
import {ColorPalette} from "../../../utils/Color";

export const useStyledWords = (keywords: Array<string>, appQuery: AppQuery<StoredFilterElement>, bold?: boolean, italic?: boolean): ReadonlyArray<StyledWords> => {
    return useMemo<ReadonlyArray<StyledWords>>(() => {
        const filters: ReadonlyArray<StyledWords> = appQuery.filters.map(f => ({
            searchWords: [f.displayName, f.id],
            style: {
                color: [ColorPalette.getColor(f.color).pastel.toString()]
            }
        }));

        return [
            {
                style: {
                    bold,
                    italic,
                },
                searchWords: keywords
            },
            ...filters
        ];
    }, [appQuery.query, appQuery.filters]);
};
