import {StoreActionType, StoreState} from "../store";
import {StoreActionReducer} from "../reducer";
import {FilterElement} from "../../types/AppQuery";

export interface StoreActionAddFilter {
    type: StoreActionType.ADD_FILTER;
    filter: FilterElement;
}

export const addFilterAction = (filter: FilterElement): StoreActionAddFilter => ({
    type: StoreActionType.ADD_FILTER,
    filter
});

export const addFilterReducer: StoreActionReducer<StoreActionType.ADD_FILTER> = (state, action): StoreState | void => {
    const lastFilter = Object.values(state.filters).pop();
    const nextColor = lastFilter === undefined ? 0 : lastFilter.color + 1;

    const filterIndex = state.filters.findIndex(f => f.id === action.filter.id);
    const newFilter = {
        ...action.filter,
        color: nextColor
    };
    if (filterIndex === -1) {
        state.filters.push(newFilter)
    } else {
        state.filters[filterIndex] = newFilter;
    }
};
