/**
 * Interfaces for the  request and responses of the plot data.
 * These values are decoupled from the actual graph implementation and should be opaque to it.
 *
 * The response define what information we want to show in the graph in terms of axis data, titles, etc.
 * Should also have the data points and the type of graph we want to render - each point can also have a link to the
 * Document we are referencing.
 */

export interface PlotDataRequest {
    query: string;
    maxArticles: number;
    filters: PlotDataRequestFilters;
    sources: ReadonlySet<string>;
}

interface PlotDataRequestFilters {
    keywords?: Array<string>;
    entities?: Array<string>
}

export interface PlotDataResponse {
    title: string;
    x_axis: {
        title: string;
        range: [number, number] | [string, string]
    },
    y_axis: {
        title: string;
        range: [number, number] | [string, string]
        ticks: Array<{
            name: string;
            value: string;
        }>
    },
    data: Array<PlotData>
}

export enum DataType {
    BUBBLE = 'bubble'
}

// Base data
interface BasePoint {
    x: string | number; // x axis could be a date or a number
    y: number;
    legend: string;
    title: string;
    document_id: string;
    type: DataType; // It's possible that we need to pull this up to the series - AFAIK the series can only hold 1 type of points
}

export type Point = BubblePoint;

export interface PlotData {
    id: string;
    name: string;
    filter_type: keyof PlotDataRequestFilters;
    points: Array<Point>;
}

// Bubble data
interface BubblePoint extends BasePoint {
    type: DataType.BUBBLE;
    size: number;
    shape?: string;
    content?: string;
}
