import {StoreActionType, StoreState} from "../store";
import {StoreActionReducer} from "../reducer";

export interface StoreActionResetData {
    type: StoreActionType.RESET_DATA;
}

export const resetDataAction = (): StoreActionResetData => ({
    type: StoreActionType.RESET_DATA,
});

export const resetDataReducer: StoreActionReducer<StoreActionType.RESET_DATA> = (state, _action): StoreState | void => {
    state.sessionId = (new Date()).getTime().toString(36);
    state.query = '';
    state.entities = {};
    state.filters = [];
    state.documentCount = undefined;
    state.treeFilter = {
        content: []
    };
    state.graphStatus = {
        lastHoveredPoint: undefined,
        lastClickedPoint: undefined
    };
    state.documentsStore = {
        documents: {},
        selectedDocuments: {}
    };
    state.documentCount = 0;
};
