import {StoreActionType, StoreState} from "../store";
import {StoreActionReducer} from "../reducer";

export interface StoreActionClearFilters {
    type: StoreActionType.CLEAR_FILTERS;
}

export const clearFiltersAction = (): StoreActionClearFilters => ({
    type: StoreActionType.CLEAR_FILTERS
});

export const clearFiltersReducer: StoreActionReducer<StoreActionType.CLEAR_FILTERS> = (state, _action): StoreState | void => {
    state.filters = [];
};
