import * as React from 'react';
import {PaperViewerWrapper} from "../PaperViewerWrapper";
import {PaperViewerParagraph} from "../PaperViewerParagraph";
import {Box} from '@mui/material';
import {DocumentClinicalTrial} from "../../../types/DocumentData";
import {PaperViewerDocumentProps} from "./PaperViewerDocumentProps";
import {useStyledWords} from "./useStyledWords";
import {useStyledChunks} from "./useStyledChunks";
import {joinOrUndefined} from "../../../utils/Array";

export const PaperViewerDocumentClinicalTrial: React.FunctionComponent<PaperViewerDocumentProps<DocumentClinicalTrial>> = (props) => {

    const searchStyles = useStyledWords(props.document.highlightKeywords, props.appQuery, true, false);
    const searchStylesTitle = useStyledWords(props.document.highlightKeywords, props.appQuery, false, true);
    const chunks = useStyledChunks(props.document.highlighting, props.appQuery.filters);

    const sponsor = joinOrUndefined(props.document.sponsor, ', ');
    const mesh = emptyStringIfUndefinedNoDash(joinOrUndefined(props.document.mesh, ', '));
    const phase = emptyStringIfUndefined(props.document.phase);
    const why_stopped =  emptyStringIfUndefined(props.document.why_stopped);

    function emptyStringIfUndefined(value: unknown): string {
        if (value === undefined || value === "") {
            return "";
        } else {
            return String(' - ' + value);
        }
    }

    function emptyStringIfUndefinedNoDash(value: unknown): string {
    if (value === undefined || value === "") {
        return "";
    } else {
        return String(value);
        }
    }

    return (
        <Box mr={2}>
            <PaperViewerWrapper
                title={props.document.title}
                link={"https://clinicaltrials.gov/ct2/show/" + props.document.id}
                styledWords={searchStylesTitle}
            >
                <PaperViewerParagraph title={"Sponsor:"}
                                      content={`${sponsor} - ${new Date(props.document.date).toLocaleDateString('en-US')}`}
                                      styledWords={searchStyles}
                                      isInline
                />
                <PaperViewerParagraph title={"Paper id:"}
                                      content={props.document.id}
                                      isInline
                />
                <PaperViewerParagraph title={"Status:"}
                                      content={props.document.status + phase + why_stopped}
                                      styledWords={searchStyles}
                                      isInline
                />
                <PaperViewerParagraph title={"Brief Summary"}
                                      content={props.document.abstract}
                                      styledWords={searchStyles}
                                      styledChunks={chunks.abstract}
                />
                <PaperViewerParagraph title={"MeSH"}
                                      content={mesh}
                                      styledWords={searchStyles}
                />
            </PaperViewerWrapper>
        </Box>
    );
};
