import {DataType, PlotDataRequest, PlotDataResponse} from "../types/PlotData";
import {useQuery} from "react-query";
import axios from "axios";
import {BaseDocument, DocumentType, ScicartaDocument} from "../types/DocumentData";
import {useStoreDispatch} from "../store/store";
import {TreeFilterElement, TreeFilterType} from "../types/TreeFilter";
import {documentsAddDocumentAction} from "../store/actions/documents/addDocuments";
import {abbreviate} from "../utils/Number";
import Config from "../Config";
import {useSelector} from "react-redux";
import {getDocumentCount, getFiltersAsRecord} from "../store/selectors";
import {startSessionAction} from "../store/actions/startSession";
import {useState} from "react";

export const getDocumentType = (documentId: string) : DocumentType => {
    if (documentId.startsWith('NCT')) {
        return DocumentType.CLINICAL_TRIAL;
    } else if (documentId.includes('.')) {
        return DocumentType.BIORXIV;
    }

    return DocumentType.PUBMED;
}

const sanitizeAndAbbreviate = (numberLike: string | number | undefined | null): string => {
    if (numberLike === undefined || numberLike === null) {
        return '0';
    }

    if (typeof numberLike === 'string') {
        numberLike = Number.parseInt(numberLike);
    }

    return abbreviate(numberLike);
}

const buildFilterTree = (container: Array<TreeFilterElement>, rawEntities: object, entityMap: Record<string, string>): void => {
    Object.entries(rawEntities).forEach((([name, rawContent]) => {
        const element: TreeFilterElement = {
            type: TreeFilterType.GROUP,
            name,
            content: []
        };

        if (Array.isArray(rawContent)) {
            element.content.push(...(rawContent.map((item): TreeFilterElement => {
                const id = item.conceptId as string;
                return {
                    id,
                    type: TreeFilterType.ITEM,
                    name: entityMap[id],
                    foundCount: item.count,
                    foundAlias: item.foundNames
                };
            })));
        } else {
            buildFilterTree(element.content, rawContent, entityMap);
        }

        container.push(element);
    }));
}

/**
 * This is an implementation of  API-split using the first version of the plot.
 * The actual response of the service includes lots more of information.
 * Includes:
 *  - plot data
 *  - entities
 *  - document
 *
 * This service will extract all that information and store in separate objects for retrieving, this will allow
 * a seamless transition to the new api without affecting the architecture.
 * @param request
 */
export const usePlotData = (request?: PlotDataRequest) => {
    const dispatch = useStoreDispatch();
    const filters = useSelector(getFiltersAsRecord);
    const count = useSelector(getDocumentCount);
    const [loadingPercent, setLoadingPercent] = useState(0);

    const { isLoading, isRefetching, error, data, refetch } = useQuery({
        queryKey: [ 'plot-data', request?.query ? {
            query: request.query,
            maxArticles: request.maxArticles,
            filter_entities: request.filters.entities,
            filter_keywords: request.filters.keywords,
            sources: [...request.sources].join(', ')
        } : undefined ],
        queryFn: async (): Promise<PlotDataResponse> => {
            if (!request?.query) {
                throw new Error('Invalid path. This is likely a bug');
            }

            const queryParams: Array<string> = [
                `search_term=${request.query}`,
                `sources=${[...request.sources].join(',')}`,
                `article_max=${request.maxArticles}`
            ];

            if (request.filters.keywords) {
                queryParams.push(`optional_keywords=${request.filters.keywords.join(',')}`);
            }

            if (request.filters.entities) {
                queryParams.push(`entities=${request.filters.entities?.join(',')}`);
            }

            setLoadingPercent(0);
            const startFetching = new Date().getTime() / 1000;
            const timeout = setInterval(() => {
                const currentDate = new Date().getTime() / 1000;
                const loadingTime = 4 + (count ?? 200) / 200;
                const loadingEstimate = ((currentDate - startFetching) / loadingTime) * 100;
                setLoadingPercent(Math.min(loadingEstimate, 99));
            }, 200);

            const response = await axios.get(
                `api/plot?&${queryParams.join('&')}`,
                // {
                //     onDownloadProgress: (progressEvent) => {
                //         const total = parseFloat(progressEvent.total);
                //         const loaded = progressEvent.loaded;
                //
                //         const percentCompleted = Math.floor(loaded / total * 100)
                //         setLoadingPercent(percentCompleted);
                //     }
                // }
            );
            clearInterval(timeout);
            setLoadingPercent(100);

            // extract documents
            const documents: Record<string, ScicartaDocument> = {};

            const plotData = {
                title: response.data.layout.title,
                x_axis: {
                    title: '',
                    range: response.data.layout.xaxis.range
                },
                y_axis: {
                    title: response.data.layout.yaxis.title,
                    range: response.data.layout.yaxis.range,
                    ticks: response.data.layout.yaxis.tickvals.map((v: string, i: number) => ({
                        name: response.data.layout.yaxis.ticktext[i],
                        value: v
                    }))
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data: response.data.data.map((plotData: any) => {


                    const element = filters[plotData.name] ? filters[plotData.name] : undefined;

                    let displayName: string;
                    if (element) {
                        displayName = element?.displayName ?? 'Most recent papers';
                    } else {
                        displayName = plotData.name;
                    }

                    const name = `${displayName.length > 11 ? displayName.slice(0, 11) + '...' : displayName} (${sanitizeAndAbbreviate(plotData.x.length)})`;
                    const nameForLegends = `${displayName}`;

                    return {
                        id: plotData.name,
                        name,
                        filter_type: 'keywords', // This value can be obtained checking if the name is part of the entities list
                        points: plotData.x.map((x: string, i: number) => {
                            const documentId: string = plotData.customdata[i].pmid;

                            const type = getDocumentType(documentId);

                            const displayName = nameForLegends; // The name will change if we load entities

                            // Extract legend
                            let legend = '';
                            switch (type) {
                                case DocumentType.PUBMED:
                                    legend = `${displayName} | ${sanitizeAndAbbreviate(plotData.citation_count[i])} citations paper`;
                                    break;
                                case DocumentType.BIORXIV:
                                    legend = `${displayName} | Downloads ${sanitizeAndAbbreviate(plotData.citation_count[i])}`;
                                    break;
                                case DocumentType.CLINICAL_TRIAL:
                                    legend = `${displayName} | Phase ${plotData.customdata[i].phase} trial`
                                    break;
                            }

                            // Extract shape
                            let shape: string | undefined = undefined;
                            switch (type) {
                                case DocumentType.PUBMED:
                                    break;
                                case DocumentType.BIORXIV:
                                    shape = 'square';
                                    break;
                                case DocumentType.CLINICAL_TRIAL:
                                    shape = 'diamond';
                                    break;
                            }

                            let defaultHighlightProperty: string | undefined = undefined;
                            switch (type) {
                                case DocumentType.PUBMED:
                                    defaultHighlightProperty = 'abstract';
                                    break;
                                case DocumentType.CLINICAL_TRIAL:
                                    defaultHighlightProperty = 'abstract';
                                    break;
                            }

                            // Build document
                            const documentBase: Omit<BaseDocument, 'type'> = {
                                id: documentId,
                                highlightKeywords: response.data['query_keywords'],
                                highlighting: {
                                }
                            };

                            if (defaultHighlightProperty) {
                                documentBase.highlighting[defaultHighlightProperty] = {
                                    entities: plotData.customdata[i].entities
                                }
                            }

                            switch (type) {
                                case DocumentType.PUBMED:
                                    documents[documentId] = {
                                        ...documentBase,
                                        type,
                                        title: plotData.customdata[i].title,
                                        abstract: plotData.customdata[i].abstract,
                                        brief_summary: plotData.customdata[i].brief_summary,
                                        journal: plotData.customdata[i].journal,
                                        publication_type: plotData.customdata[i].publication_type,
                                        doi_href: plotData.customdata[i].doi,
                                        citations: plotData.customdata[i].citation ?? 0,
                                        affiliation: plotData.customdata[i].affiliation?.split(',') ?? [],
                                        authors: plotData.customdata[i].author?.split(',') ?? [],
                                        companies: plotData.customdata[i].company?.split(',') ?? [],
                                        substances: plotData.customdata[i].substance?.split(',') ?? [],
                                        mesh: plotData.customdata[i].mesh?.split(',') ?? [],
                                        keywords: plotData.customdata[i].keywords?.split(',') ?? [],
                                        date: x,
                                    };
                                    break;
                                case DocumentType.CLINICAL_TRIAL:
                                    documents[documentId] = {
                                        ...documentBase,
                                        type,
                                        phase: plotData.customdata[i].phase,
                                        status: plotData.customdata[i].status,
                                        why_stopped: plotData.customdata[i].why_stopped,
                                        title: plotData.customdata[i].title,
                                        abstract: plotData.customdata[i].abstract,
                                        sponsor: plotData.customdata[i].author?.split(',') ?? [],
                                        mesh: plotData.customdata[i].mesh?.split(',') ?? [],
                                        date: x,
                                        publication_type: plotData.customdata[i].publication_type,
                                        journal: plotData.customdata[i].journal,
                                    };
                                    break;
                            }

                            // Build bubble data point
                            return {
                                x,
                                y: plotData.y[i],
                                legend,
                                title: plotData.customdata[i].title,
                                document_id: documentId,
                                type: DataType.BUBBLE,
                                size: plotData.marker.size[i],
                                shape,
                                content: plotData.marker.shape[i]
                            }
                        })
                    }
                })
            };

            dispatch(documentsAddDocumentAction(Object.values(documents)));
            if ((request.filters.keywords === undefined || request.filters.keywords.length === 0) && (request.filters.entities === undefined || request.filters.entities.length === 0)) {
                dispatch(startSessionAction());
            }
            return plotData;
        },
        // Controls if we want to hit the api after updating the filters/query
        enabled: Config.updateGraphOnType ?  !!request?.query : false,
        retry: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    return {
        isLoading: isLoading || isRefetching,
        loadingPercent,
        error,
        data,
        refetch
    };
}
