import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import {ModalDialogTitle} from "./ModalDialogTitle";
import {Box, Link } from "@mui/material";
import {Link as LinkRouter} from "react-router-dom";

const ModalDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    }
}));

interface TermsOfUseProps {
    isOpen : boolean;
    handleClose: () => void;
}

export const TermsOfUse: React.FunctionComponent<TermsOfUseProps> = (props) => {
    return (
        <div>
            <ModalDialog

                fullWidth={true}
                maxWidth={'lg'}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}
            >
                <ModalDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Terms Of Use
                </ModalDialogTitle>
                <DialogContent dividers>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: '100%',
                        }}
                    >

                        <Typography gutterBottom align={"justify"} fontWeight={"lighter"} variant={"caption"}>
                            <Typography fontWeight={"bold"} variant={"h6"} gutterBottom>
                            Scicarta Inc. Terms of Use
                            </Typography>
                            <Typography paragraph={true} sx={{ fontStyle: 'italic' }} >
                            Last updated on March 23, 2024
                            </Typography>
                            <Typography fontWeight={"bold"} variant={"h6"} gutterBottom>
                            ACCEPTANCE OF THE TERMS OF USE
                            </Typography>

                            <Typography paragraph={true} >
                                These terms of use are entered into by and between You and Scicarta, Inc. (“<b>Scicarta</b>”, “<b>we</b>”, or “<b>us</b>”). The following terms and conditions (these “<b>Terms of Use</b>”), govern your access to and use of <Link href={"https://scicarta.com"} underline={"hover"}>https://scicarta.com</Link>, including any content, functionality, and services offered on or through <Link href={"https://scicarta.com"} underline={"hover"}>https://scicarta.com</Link> (the “<b>Site</b>”), whether as a guest or a registered user.
                            </Typography>
                            <Typography paragraph={true} >
                                Please read the Terms of Use carefully before you start to use the Site. <b>By using the Site, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at
                            </b> <Link
                                    component={LinkRouter}
                                    underline="hover"
                                    to="/terms-of-use"
                                >
                                    https://scicarta.com/terms-of-use
                                </Link>
                              <b> and </b>
                                <Link
                                    component={LinkRouter}
                                    underline="hover"
                                    to="/privacy-policy"
                                >
                                    https://scicarta.com/privacy-policy
                                </Link>

                              <b>, incorporated herein by reference</b>. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Site.
                            </Typography>

                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            CHANGES TO THE TERMS OF USE
                            </Typography>
                            <Typography paragraph={true} >
                            We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Site thereafter. However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice before the date the change is posted on the Site. Your continued use of the Site following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            ACCESSING THE SITE AND ACCOUNT SECURITY
                            </Typography>
                            <Typography paragraph={true} >
                            We reserve the right to withdraw or amend this Site, and any services or materials we provide on the Site, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Site, or the entire Site, to users, including registered users.
                            </Typography>
                            <Typography paragraph={true} >
                            You are responsible for making all arrangements necessary for you to have access to the Site, and ensuring that all persons who access the Site through your Internet connection are aware of these Terms of Use and comply with them.
                            </Typography>
                            <Typography paragraph={true} >
                            To access the Site or some of the resources it offers, you may be asked to provide information. It is a condition of your use of the Site that all the information you provide on the Site is correct, current, and complete. You agree that all information you provide to register with this Site or otherwise, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.
                            </Typography>
                            <Typography paragraph={true} >
                            If you choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Site or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.
                            </Typography>
                            <Typography paragraph={true} >
                            We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            INTELLECTUAL PROPERTY RIGHTS
                            </Typography>
                            <Typography paragraph={true} >
                            The Site and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Scicarta, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                            </Typography>
                            <Typography paragraph={true} >
                            These Terms of Use permit you to use the Site for your internal business use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Site.
                            </Typography>
                            <Typography paragraph={true} >
                            You must not modify copies of any materials from this Site, use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text, or delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.
                            </Typography>
                            <Typography paragraph={true} >
                            You must not access or use for any commercial purposes any part of the Site.
                            </Typography>
                            <Typography paragraph={true} >
                            If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Site in breach of the Terms of Use, your right to use the Site will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Site or any content on the Site is transferred to you, and all rights not expressly granted are reserved by Scicarta. Any use of the Site not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            TRADEMARKS
                            </Typography>
                            <Typography paragraph={true} >
                            The Scicarta company name, the Scicarta logo, and all related names, logos, product and service names, designs, and slogans are trademarks of Scicarta or its affiliates or licensors. You must not use such marks without the prior written permission of Scicarta. All other names, logos, product and service names, designs, and slogans on this Site are the trademarks of their respective owners.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            PROHIBITED USES
                            </Typography>
                            <Typography paragraph={true} >
                            You may use the Site only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Site:
                            <ul>
                                <li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>
                                <li>To impersonate or attempt to impersonate Scicarta, a Scicarta employee, another user, or any other person or entity (including, without limitation, by using email addresses associated with any of the foregoing).</li>
                                <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Site, or which, as determined by us, may harm Scicarta or users of the Site or expose them to liability.</li>
                            </ul>

                            Additionally, you agree not to:
                            <ul>
                                <li>Use the Site in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Site, including their ability to engage in real time activities through the Site.</li>
                                <li>Use any robot, spider, or other automatic device, process, or means to access the Site for any purpose, including monitoring or copying any of the material on the Site.</li>
                                <li>Use any manual process to monitor or copy any of the material on the Site or for any other unauthorized purpose without our prior written consent.</li>
                                <li>Use any device, software, or routine that interferes with the proper working of the Site.</li>
                                <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
                                <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Site, the server on which the Site is stored, or any server, computer, or database connected to the Site.</li>
                                <li>Attack the Site via a denial-of-service attack or a distributed denial-of-service attack.</li>
                                <li>Otherwise attempt to interfere with the proper working of the Site.</li>
                            </ul>

                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            COPYRIGHT INFRINGEMENT
                            </Typography>
                            <Typography paragraph={true} >
                            The U.S. Digital Millennium Copyright Act (“DMCA”) provides recourse to copyright owners who believe that their rights under the United States Copyright Act have been infringed by acts of third parties over the Internet. If you believe that any content uploaded or otherwise made available on the Services infringes upon any copyright which you own or control, you may so notify us in accordance with our DMCA process at info@scicarta.com.
                            </Typography>
                            <Typography paragraph={true} >
                            In accordance with the DMCA and other applicable law, we have adopted a policy of terminating, in appropriate circumstances and at our sole discretion, the accounts of users of the Service who are deemed to be repeat infringers. Cursor may also at its sole discretion limit access to the Service or terminate the account of any user who infringes any intellectual property rights of others, whether or not there is any repeat infringement.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            RELIANCE ON INFORMATION POSTED
                            </Typography>
                            <Typography paragraph={true} >
                            The information presented on or through the Site is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Site, or by anyone who may be informed of any of its contents.
                            </Typography>
                            <Typography paragraph={true} >
                            This Site may include content provided by third parties. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by Scicarta, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of Scicarta. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            CHANGES TO THE SITE
                            </Typography>
                            <Typography paragraph={true} >
                            We may update the content on this Site from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Site may be out of date at any given time, and we are under no obligation to update such material.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            INFORMATION ABOUT YOU AND YOUR VISITS TO THE SITE
                            </Typography>
                            <Typography paragraph={true} >
                            All information we collect on this Site is subject to our Privacy Policy [INSERT AS LINK TO PRIVACY POLICY]. By using the Site, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            GEOGRAPHIC RESTRICTIONS
                            </Typography>
                            <Typography paragraph={true} >
                            The owner of the Site is based in the State of Massachusetts in the United States. We make no claims that the Site or any of its content is accessible or appropriate outside of the United States. Access to the Site may not be legal by certain persons or in certain countries. If you access the Site from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            DISCLAIMER OF WARRANTIES
                            </Typography>
                            <Typography paragraph={true} >
                            You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Site will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SITE LINKED TO IT.
                            </Typography>
                            <Typography paragraph={true} >
                            YOUR USE OF THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. UNLESS OTHERWISE STATED, THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER SCICARTA NOR ANY PERSON ASSOCIATED WITH SCICARTA MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER SCICARTA NOR ANYONE ASSOCIATED WITH SCICARTA REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                            </Typography>
                            <Typography paragraph={true} >
                            TO THE FULLEST EXTENT PROVIDED BY LAW, SCICARTA HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                            </Typography>
                            <Typography paragraph={true} >
                            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            LIMITATION ON LIABILITY
                            </Typography>
                            <Typography paragraph={true} textTransform={"uppercase"}>
                            TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL SCICARTA, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY SITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER SITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
                            </Typography>
                            <Typography paragraph={true} >
                            THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            INDEMNIFICATION
                            </Typography>
                            <Typography paragraph={true} >
                            You agree to defend, indemnify, and hold harmless Scicarta, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Site, including, but not limited to, any use of the Site’s content, services, and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Site.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            GOVERNING LAW AND JURISDICTION
                            </Typography>
                            <Typography paragraph={true} >
                            All matters relating to the Site and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the Commonwealth of Massachusetts without giving effect to any choice or conflict of law provision or rule (whether of the Commonwealth of Massachusetts or any other jurisdiction).
                            </Typography>
                            <Typography paragraph={true} >
                            Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Site shall be instituted exclusively in the federal courts of the United States or the courts of the Commonwealth of Massachusetts, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            ARBITRATION
                            </Typography>
                            <Typography paragraph={true} >
                            At Scicarta’s sole discretion, it may require You to submit any disputes arising from the use of these Terms of Use or the Site, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying law of the Commonwealth of Massachusetts.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            LIMITATION ON TIME TO FILE CLAIMS
                            </Typography>
                            <Typography paragraph={true} textTransform={"uppercase"} >
                            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            WAIVER AND SEVERABILITY
                            </Typography>
                            <Typography paragraph={true} >
                            No waiver by Scicarta of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Scicarta to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision. If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            ENTIRE AGREEMENT
                            </Typography>
                            <Typography paragraph={true} >
                            The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and Scicarta Software Corporation regarding the Site and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Site.
                            </Typography>
                            <Typography sx={{fontWeight: 'bold'}} variant={"h6"} gutterBottom>
                            YOUR COMMENTS AND CONCERNS
                            </Typography>
                            <Typography paragraph={true} >
                            This website is operated by Scicarta, Inc., a corporation located at 585 Massachusetts Avenue, 4th Floor, Cambridge, Massachusetts 02139.
                            </Typography>
                            <Typography paragraph={true} >
                            All other feedback, comments, requests for technical support, and other communications relating to the Site should be directed to: <Link href={"mailto:info@scicarta.com"} underline={"hover"}>info@scicarta.com</Link>
                            </Typography>
                        </Typography>
                    </Box>
                </DialogContent>
            </ModalDialog>
        </div>


    );
}
